.mint-content-container {
  height: 100vh;
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 180px;
  }
}

.hole-bkgd-mint {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: -1;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
}
