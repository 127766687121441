.wallet-connect {

  a {
    color: #3BFFE2;
    font-size: 16px;
  }
}

.eth-address {
  background: black;
  border: 1px solid #3BFFE2;
  border-radius: 8px;
  padding: 4px 6px 4px 10px;
}
