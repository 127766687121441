// Colors
$color-primary: #5B90F6; //Blue
// $color-neutral-1: #f3f3f3; /*ex-light*/
$color-neutral-2: #caccce; /*light*/
$color-neutral-3: #758090; /*medium*/
$color-neutral-4: #8d8d8d; /*muted*/
// $color-neutral-5: #5e5e5e; /*dark*/
$color-neutral-6: #182032; /*ex-dark*/
$color-black: #000000;
$color-white: #fff;
$color-error: #e13535;
$color-green: #1bb870;
$color-yellow: #f3cc33;
$color-aqua: #3BFFE2;

// Theme
$theme-colors: (
  "primary": $color-primary,
  "secondary": $color-aqua,
  "light": $color-neutral-2,
  "medium": $color-neutral-3,
  "muted": $color-neutral-4,
  "dark": $color-neutral-6,
  "black": $color-black,
  "danger": $color-error,
  "warning": $color-yellow,
  "aqua": $color-aqua
);

/* Fonts */
$font-family-sans-serif: 'Josefin Sans', -apple-system, BlinkMacSystemFont,
  Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

/* Font Sizes */
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: ($font-size-base * 0.75); //12
$font-size-lg: ($font-size-base * 1.5); //24
$font-size-xl: ($font-size-base * 2); //32
$font-size-xxl: ($font-size-base * 4); //64

/* Font Weights */
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-demibold: 600;
$font-weight-bold: 700;

/* Spacing */
$spacing: $font-size-base; //16px
$spacing-xsm: ($spacing * 0.75); //12
$spacing-sm: ($spacing * 0.875); //14px
$spacing-lg: ($spacing * 1.125); //18px
$spacing-xl: ($spacing * 1.5); //24px
$spacing-xxl: ($spacing * 2.25); //36px
$spacing-xxxl: ($spacing * 2.5); //40px

// common padding and border
$border-radius: 0.25rem; //4px
$border-radius-lg: 0.5rem; //8px
$border-radius-sm: 0.125rem; //2px
$border-width: 1px;

/*buttons*/
$btn-border-radius: $border-radius;

$btn-padding-x: $spacing;
$btn-padding-y: $spacing;
$btn-font-weight: $font-weight-medium;

$btn-primary-color: $color-neutral-2;
$btn-primary-bg: $color-primary;
$btn-primary-border: $btn-primary-bg;

$btn-link-disabled-color: $color-neutral-2;

/*forms*/
$input-padding-x: 1.25rem;
$input-padding-y: $spacing;

$input-bg: $color-white;
$input-color: $color-neutral-4;
$input-placeholder-color: $color-neutral-4;

$input-btn-focus-box-shadow: none;
$input-border: $color-neutral-2;

$input-border-focus: none;
$input-box-shadow-focus: none;

$input-height: 56px;

/*Tables*/
$table-cell-padding: $spacing-lg;

$table-bg-accent: $color-black;
$table-bg-hover: $color-error;

$table-border-color: $color-neutral-2;
