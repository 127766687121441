.wallet-content-container {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.attribute-container {
  width: 100%;
  max-width: 400px;
}

.reveal-placeholder {
  border-radius: 24px;
  height: 320px;
  width: 320px;

  @media (min-width: 768px) {
    height: 400px;
    width: 400px;
  }
}

.trait-box {
  background: #1B1B1B;
  border: 1px solid #3B3B3B;
  border-radius: 4px;
  padding: 6px 4px;
  margin: 8px 0;
  max-width: 196px;
}

.attribute-value-type {
  color: #7B7B7B;
  font-size: 10px;
}

.attribute-value {
  font-size: 16px;
}
.attribute-percent {
  font-size: 12px;
}

.wallet-loader {
  max-width: 310px;
}