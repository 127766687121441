.navbar {
  justify-content: space-between;
  padding: 24px 12px;

  @media (min-width: 768px) {
    justify-content: end;
    padding: 32px 24px;
  }
}

.bg-dark {
  background-color: black !important;
}

.nav-logo{
  width: 193px;
}
.nav-link {
  margin-right: 0;

  @media (min-width: 768px) {
    margin-right: 16px;
  }
}