.card-header {
  padding: 0;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: left;
}

.card {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 0;
  background-color: $color-black;
  color: $color-neutral-2;

  & .btn-link {
    color: white;
  }

  & .btn-link:hover,
  .btn-link:active,
  .btn-link:visited,
  .btn-link:focus {
    color: #37FADC;
    background-color: none;
    text-decoration: none;
  }
}

.card-body {
  & img {
    width: 100%;
  }
}
