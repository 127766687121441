@import "1_settings/_bootstrap-overrides.scss";
@import "bootstrap";
@import "1_settings/typography.scss";
@import "1_settings/global.scss";

@import "2_overrides/accordian.scss";
@import "2_overrides/buttons.scss";

@import "3_components/navigation.scss";
@import "3_components/wallet-connect.scss";

@import "4_layout/home.scss";
@import "4_layout/mint.scss";
@import "4_layout/wallet.scss";
@import "4_layout/claims.scss";

