.top-content-container {
  margin-top: 60px;
}

.hole-bkgd {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: -1;
  max-width: 1350px;
  left: 50%;
    transform: translateX(-50%);
}

.hero-image {
  position: relative;
    margin-top: 72px;
    height: auto;


  @media (min-width: 768px) {
    margin-top: 80px;
  }


  img {
    width: 150%;
    position: inherit;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 576px) { 
      width: 140%;
    }

    @media (min-width: 768px) {
      position: unset;
      width: 100%;
      left: unset;
      transform: unset;
    }
  }
}

.what-section {
  background: rgb(38,0,38);
  background: linear-gradient(180deg, rgba(38,0,38,1) 0%, rgba(0,0,0,1) 100%);

  .content {
    padding-top: 140px;
    padding-bottom: 140px;

    h5 {
      max-width: 800px;
    }
  }
}

// SLIDE SHOW

.nft-slideshow {
  height: 298px;
  max-width: 2400px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.nft-slideshow > div {
  height: 200px;
  width: 4800px;
  background: url("../../images/home/slideshow.png");
  background-size: auto 298px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.nft-slideshow .mover-1 {
  animation: moveSlideshow 60s linear infinite;
}
.nft-slideshow .mover-2 {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  background-position: 0 -200px;
  animation: moveSlideshow 60s linear infinite;
}

@keyframes moveSlideshow {
  100% { 
    transform: translateX(-66.6666%);  
  }
}

.about-section {
  margin-top: 194px;
  margin-bottom: 194px;
}

.about-image {
    width: 100%;
    max-width: 480px;
    border-radius: 12px;
}

.mint-section {
  margin-top: 100px;
  margin-bottom: 150px;

  img {
    max-width: 215px;
    
    @media (min-width: 768px) {
      max-width: 788px;
    }
  }
}

.faq-bkgd {
  position: absolute;
  top: 0;
  width: auto;
  height: 850px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @media (min-width: 768px) {
    height: 1080px;
  }
}

.faq-content {
  margin-top: 10px;
  
  @media (min-width: 768px) {
    margin-top: 90px;
  }
}

.footer{
  margin-top: 100px;
  margin-bottom: 100px;

  @media (min-width: 768px) {
    margin-top: 300px;
  }
}


