.btn-link {
  text-decoration: none;
}

.btn-mint {
  width: 100%;
  max-width: 200px;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}