.countdown {
  align-items: center;

  li {
    display: inline-block;
    font-size: 0.85rem;
    list-style-type: none;
    padding: 0.5em;
    text-transform: uppercase;

    @media (min-width: 576px) {
      font-size: 1rem;
      padding: 1em;
    }
  }
  
  li span {
    display: block;
    font-size: 1.85rem;

    @media (min-width: 576px) {
      font-size: 2.5rem;
    }
  }
  
}

.claim-trips {
  width: 100%;
  max-width: 280px;
  margin: 16px;
  border-radius: 8px;
}

.claim-content {
  margin: 50px 0 100px 0;
}