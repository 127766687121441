body {
  font-family: 'Josefin Sans', serif;
}

h1 {
  font-size: 32px;
  font-family: "Osbourne03-Black";

  @media (min-width: 768px) {
    font-size: 64px;
  }
}

h5 {
  font-family: 'Josefin Sans', serif;
  font-size: 20px;
  line-height: 30px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.osbourne {
  font-family: "Osbourne03-Black";
}

p {
  font-size: 18px;
}

.text-meta {
  font-size: 12px;
  font-weight: $font-weight-medium;
}

.font-weight-demibold {
  font-weight: $font-weight-demibold;
}

.font-weight-demibold {
  font-weight: $font-weight-medium;
}
