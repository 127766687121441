html, body {
  overflow-x: hidden;
}

body {
  position: relative;
  background-color: black;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
hr {
  color: rgba(0, 0, 0, 0.1);
}